import React, {
  ChangeEventHandler,
  Component,
  createRef,
  Fragment,
} from 'react';
import classNames from 'classnames';
import { BadgeList } from '@wix/members-badge-lib';
import { RoleId } from '@wix/members-domain-ts';
import { Image, ImageResizeOptions, WowImage } from 'wix-ui-tpa';
import CameraFill from 'wix-ui-icons-common/on-stage/CameraFill';

import {
  BaseComponentProps,
  DataHook,
  ElementId,
  Position,
  ProfileImageSize,
} from '../../../../../types';
import { maybeOpenContactsWindow } from '../../../../../services/navigation';
import {
  absorbArguments,
  forwardCoverRepositionAttributes,
  forwardResizedImage,
} from '../../../../../services/event-handler';
import { getElementId } from '../../../../../services/html-attributes';
import LoaderOverlay from '../../../../../common/components/LoaderOverlay';
import AvatarInput from '../../../../../common/components/AvatarInput';
import MemberName from '../../../../../common/components/MemberName';
import RoleIcons from '../../../../../common/components/RoleIcons';
import Blocked from '../../../../../common/components/Blocked';
import Button, {
  ButtonClickHandler,
} from '../../../../../common/components/Button';
import FollowOrChatButton from '../../../../../common/components/FollowOrChatButton';
import MemberMoreButton from '../../../../../common/components/MemberMoreButton';
import styles from './DesktopNonResponsiveWidget.scss';
import { RepositionButton } from '../../../../../common/components/RepositionButton';
import PrivateMemberIndicator from '../../../../../common/components/PrivateMemberIndicator';
import Avatar from '../../../../../common/components/Avatar';
import { acceptableImageExtensions } from '../../../../../constants/image';

interface ButtonProps {
  dataHook?: DataHook;
  title: string;
  isSecondary: boolean;
  opaque?: boolean;
  onClick: ButtonClickHandler;
}

enum BREAKPOINTS {
  NARROW = 659,
  WIDE = 840,
}

const ProfileImageSizeMap = {
  [ProfileImageSize.Small]: 50,
  [ProfileImageSize.Medium]: 90,
  [ProfileImageSize.Large]: 150,
};

class DesktopNonResponsiveWidget extends Component<BaseComponentProps> {
  private coverRef = createRef<HTMLDivElement>();
  state = { isMovingCover: false };

  render = () => {
    const { site, globalSettings, computed, host, profilePage } = this.props;
    const { showFollowers } = globalSettings;
    const {
      isDesignPreview,
      inCommunity,
      showCover,
      showAsBlockedByMember,
      showPrivateMemberIndicator,
      isDesktopStylesOnMobileEnabled,
    } = computed;

    if (showAsBlockedByMember) {
      return this.renderEmptyWidget();
    }

    const { isSocial } = site;
    const isFollowersVisible = showFollowers && inCommunity && isSocial;

    const containerClassName = classNames({
      [styles.profile]: true,
      [styles.designPreview]: isDesignPreview || !profilePage.isProfilePreview,
      [styles.withCover]: showCover,
      [styles.editMode]: computed.canEdit && !profilePage.isProfilePreview,
      [styles.nonSocial]: !site.isSocial,
      [this.getWidgetSizeClass(host?.dimensions)]: true,
      [styles.desktopStylesOnMobile]: isDesktopStylesOnMobileEnabled,
    });

    return (
      <div data-hook={DataHook.VerticalWidget}>
        <div className={styles.box}>
          <div className={styles.border} />
          <div className={containerClassName}>
            {showPrivateMemberIndicator && (
              <PrivateMemberIndicator alignRight={showCover} />
            )}
            {this.props.profilePage.isSaving && this.renderLoader()}
            {showCover && this.renderCover()}
            <div className={styles.layout}>
              {this.renderPhoto()}
              <div className={styles.content}>
                <div className={styles.memberDetails}>
                  {this.renderMemberDetails()}
                  {isFollowersVisible && this.renderFollowers()}
                </div>
                {this.renderButtons()}
                {this.renderActions()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  private renderEmptyWidget() {
    const { host } = this.props;
    const containerClassName = classNames({
      [styles.profile]: true,
      [styles.nonSocial]: true,
      [this.getWidgetSizeClass(host?.dimensions)]: true,
    });
    return (
      <div data-hook={DataHook.VerticalWidget}>
        <div className={styles.box}>
          <div className={styles.border} />
          <div className={containerClassName}>
            <div className={styles.layout}>
              {this.renderPhoto()}
              <div className={styles.content}>
                <div className={styles.memberDetails}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  private readonly getWidgetSizeClass = (dimensions?: {
    width: number;
    height: number;
  }) => {
    if (!dimensions?.width) {
      return styles.fullWidth;
    }

    if (dimensions.width <= BREAKPOINTS.NARROW) {
      return styles.small;
    }

    if (dimensions.width < BREAKPOINTS.WIDE) {
      return styles.medium;
    }

    return styles.large;
  };

  private readonly renderLoader = () => (
    <LoaderOverlay className={styles.loader} />
  );

  private readonly handleCoverPositionChange = (event: MouseEvent) => {
    const { host, handlers } = this.props;
    const { changeCoverPosition } = handlers;
    const coverWidth = host?.dimensions?.width ?? 0;
    forwardCoverRepositionAttributes(changeCoverPosition, coverWidth)(event);
  };

  private readonly startRepositioningCover = () => {
    const { profilePage } = this.props;
    const { isCoverRepositionMode } = profilePage;

    if (isCoverRepositionMode && this.coverRef.current) {
      this.coverRef.current.addEventListener(
        'mousemove',
        this.handleCoverPositionChange,
      );
      this.setState({ isMovingCover: true });
    }
  };

  private readonly stopRepositioningCover = () => {
    const { profilePage } = this.props;
    const { isCoverRepositionMode } = profilePage;
    if (isCoverRepositionMode && this.coverRef.current) {
      this.coverRef.current.removeEventListener(
        'mousemove',
        this.handleCoverPositionChange,
      );
      this.setState({ isMovingCover: false });
    }
  };

  private readonly renderCoverImage = () => {
    const { computed } = this.props;
    const { cardProfileCoverPhoto } = computed;

    if (!cardProfileCoverPhoto?.src) {
      return <div className={classNames(styles.cover, styles.coverColor)} />;
    }

    return (
      <>
        <Image
          src={cardProfileCoverPhoto.src}
          data-hook={DataHook.HighQualityCover}
          className={styles.cover}
          {...(cardProfileCoverPhoto && {
            focalPoint: {
              x: cardProfileCoverPhoto.position.x,
              y: cardProfileCoverPhoto.position.y,
            },
          })}
          resize={ImageResizeOptions.cover}
        />
      </>
    );
  };

  private readonly renderCoverWowImage = () => {
    const { computed } = this.props;
    const { cardProfileCoverPhoto } = computed;

    if (!cardProfileCoverPhoto?.src) {
      return <div className={classNames(styles.cover, styles.coverColor)} />;
    }

    return (
      <>
        <WowImage
          src={cardProfileCoverPhoto.src}
          data-hook={DataHook.HighQualityCover}
          className={styles.cover}
          {...(cardProfileCoverPhoto && {
            focalPoint: {
              x: cardProfileCoverPhoto.position.x,
              y: cardProfileCoverPhoto.position.y,
            },
          })}
          resize={ImageResizeOptions.cover}
          sourceWidth={cardProfileCoverPhoto.imageProps.rw}
          sourceHeight={cardProfileCoverPhoto.imageProps.rh}
        />
      </>
    );
  };

  private readonly renderCover = () => {
    const { compId, profilePage, handlers, computed, t } = this.props;
    const { isCoverRepositionMode } = profilePage;
    const {
      cardProfileCoverPhotoStyle,
      showCoverPhoto,
      isUiTpaImageEnabled,
      isCoverWowImageEnabled,
    } = computed;
    const coverPhotoInputId = getElementId(compId, ElementId.CoverPhotoInput);
    const { isMovingCover } = this.state;

    const { enterCoverRepositionMode, setEditCover } = handlers;

    const handleCoverUpload: ChangeEventHandler<HTMLInputElement> = (event) => {
      enterCoverRepositionMode();
      forwardResizedImage(setEditCover)(event);
    };
    const canEdit =
      computed.canEdit && !isCoverRepositionMode && showCoverPhoto;

    if (isCoverWowImageEnabled) {
      return (
        <>
          <div
            data-hook={DataHook.CoverWrapper}
            className={classNames(styles.coverWrapper, styles.coverColor)}
          >
            <div
              className={classNames(
                styles.coverBorderMargin,
                isCoverRepositionMode && styles.onTop,
              )}
              ref={this.coverRef}
              onMouseDown={this.startRepositioningCover}
              onMouseUp={this.stopRepositioningCover}
              onMouseLeave={this.stopRepositioningCover}
            >
              {this.renderCoverWowImage()}
              {profilePage.isCoverLoading && this.renderLoader()}
              {canEdit && (
                <div className={styles.expCoverPhotoContainer}>
                  <input
                    id={coverPhotoInputId}
                    className={styles.uploadInput}
                    type="file"
                    accept={acceptableImageExtensions}
                    aria-label={t('profile-widget.change-aria-label-photo')}
                    onChange={handleCoverUpload}
                  />
                  <label
                    htmlFor={coverPhotoInputId}
                    className={styles.expUploadLabel}
                  />
                  {!isCoverRepositionMode && (
                    <div className={styles.coverPhotoChange}>
                      <CameraFill className={styles.coverPhotoUploadIcon} />
                    </div>
                  )}
                </div>
              )}
              {isCoverRepositionMode && (
                <RepositionButton
                  dataHook={DataHook.DragToChangeCoverPositionCTA}
                  className={classNames(
                    styles.expRepositionCoverButton,
                    isMovingCover && styles.isMoving,
                  )}
                  text={t('profile-widget.drag-to-reposition')}
                />
              )}
            </div>
          </div>
          {isCoverRepositionMode && this.renderRepositionControls()}
        </>
      );
    }

    if (isUiTpaImageEnabled) {
      return (
        <>
          <div
            data-hook={DataHook.CoverWrapper}
            className={classNames(styles.coverWrapper, styles.coverColor)}
          >
            <div
              className={classNames(
                styles.coverBorderMargin,
                isCoverRepositionMode && styles.onTop,
              )}
              ref={this.coverRef}
              onMouseDown={this.startRepositioningCover}
              onMouseUp={this.stopRepositioningCover}
              onMouseLeave={this.stopRepositioningCover}
            >
              {this.renderCoverImage()}
              {profilePage.isCoverLoading && this.renderLoader()}
              {canEdit && (
                <div className={styles.expCoverPhotoContainer}>
                  <input
                    id={coverPhotoInputId}
                    className={styles.uploadInput}
                    type="file"
                    accept={acceptableImageExtensions}
                    aria-label={t('profile-widget.change-aria-label-photo')}
                    onChange={handleCoverUpload}
                  />
                  <label
                    htmlFor={coverPhotoInputId}
                    className={styles.expUploadLabel}
                  />
                  {!isCoverRepositionMode && (
                    <div className={styles.coverPhotoChange}>
                      <CameraFill className={styles.coverPhotoUploadIcon} />
                    </div>
                  )}
                </div>
              )}
              {isCoverRepositionMode && (
                <RepositionButton
                  dataHook={DataHook.DragToChangeCoverPositionCTA}
                  className={classNames(
                    styles.expRepositionCoverButton,
                    isMovingCover && styles.isMoving,
                  )}
                  text={t('profile-widget.drag-to-reposition')}
                />
              )}
            </div>
          </div>
          {isCoverRepositionMode && this.renderRepositionControls()}
        </>
      );
    }

    return (
      <>
        <div
          data-hook={DataHook.CoverWrapper}
          className={classNames(styles.coverWrapper, styles.coverColor)}
        >
          <div
            className={classNames(
              styles.coverBorderMargin,
              isCoverRepositionMode && styles.onTop,
            )}
            ref={this.coverRef}
            onMouseDown={this.startRepositioningCover}
            onMouseUp={this.stopRepositioningCover}
            onMouseLeave={this.stopRepositioningCover}
          >
            <div
              data-hook={DataHook.HighQualityCover}
              style={cardProfileCoverPhotoStyle || {}}
              className={classNames(
                styles.cover,
                !cardProfileCoverPhotoStyle && styles.coverColor,
              )}
            >
              {!cardProfileCoverPhotoStyle && (
                <div
                  className={classNames(styles.overlay, styles.coverOverlay)}
                />
              )}
              <div className={classNames(styles.overlay)} />
              {profilePage.isCoverLoading && this.renderLoader()}
              {canEdit && (
                <div className={styles.coverPhotoContainer}>
                  <input
                    id={coverPhotoInputId}
                    className={styles.uploadInput}
                    type="file"
                    accept={acceptableImageExtensions}
                    aria-label={t('profile-widget.change-aria-label-photo')}
                    onChange={handleCoverUpload}
                  />
                  <label
                    htmlFor={coverPhotoInputId}
                    className={styles.uploadLabel}
                  />
                  {!isCoverRepositionMode && (
                    <div className={styles.coverPhotoChange}>
                      <div className={styles.coverPhotoIco} />
                    </div>
                  )}
                </div>
              )}
              {isCoverRepositionMode && (
                <RepositionButton
                  dataHook={DataHook.DragToChangeCoverPositionCTA}
                  className={classNames(
                    styles.repositionCoverButton,
                    isMovingCover && styles.isMoving,
                  )}
                  text={t('profile-widget.drag-to-reposition')}
                />
              )}
            </div>
          </div>
        </div>
        {isCoverRepositionMode && this.renderRepositionControls()}
      </>
    );
  };

  private renderRepositionControls() {
    const { handlers, t } = this.props;
    const { leaveCoverRepositionMode, saveCoverReposition } = handlers;
    return (
      <div className={styles.repositionControlButtons}>
        {this.renderButton({
          dataHook: DataHook.CardCoverCancel,
          title: t('profile-widget.cancel'),
          isSecondary: true,
          opaque: true,
          onClick: absorbArguments(leaveCoverRepositionMode),
        })}
        {this.renderButton({
          dataHook: DataHook.CardCoverSave,
          title: t('profile-widget.save'),
          isSecondary: false,
          onClick: absorbArguments(saveCoverReposition),
        })}
      </div>
    );
  }

  private readonly renderPhoto = () => {
    const { compId, member, profilePage, computed, handlers, t } = this.props;
    const {
      canEdit,
      pictureStyle,
      profileImageSize,
      showCover,
      isUiTpaImageEnabled,
      profileLayout,
    } = computed;
    const photoInputId = getElementId(compId, ElementId.ProfilePhotoInput);
    const handlePhotoUpload = forwardResizedImage(handlers.setMemberPicture);
    const isSmallImage = profileImageSize === ProfileImageSize.Small;
    const isLargeImage = profileImageSize === ProfileImageSize.Large;

    const expProfilePhotoClasses = {
      [styles.expWithNoCover]: !showCover,
    };

    const profilePhotoClasses = {
      [styles.profilePhoto]: true,
      [styles.profilePhotoSmall]: isSmallImage,
      [styles.profilePhotoLarge]: isLargeImage,
      [styles.withNotCover]: !showCover,
    };

    if (isUiTpaImageEnabled) {
      return (
        <AvatarInput
          dataHook={DataHook.ProfilePhoto}
          className={classNames(expProfilePhotoClasses)}
          imageType={pictureStyle}
          url={profilePage.editPicture?.file ?? member.picture}
          onClick={handlePhotoUpload}
          canUpload={canEdit}
          avatarSize={profileImageSize}
          profileLayout={profileLayout}
        />
      );
    }

    return (
      <Avatar
        dataHook={DataHook.ProfilePhoto}
        className={classNames(profilePhotoClasses)}
        layoutType={pictureStyle}
        url={profilePage.editPicture?.file ?? member.picture}
        name={null}
        pictureHeight={ProfileImageSizeMap[profileImageSize]}
      >
        <div className={styles.overlay} />
        {canEdit && (
          <div className={styles.profilePhotoIco}>
            <input
              id={photoInputId}
              className={styles.uploadInput}
              type="file"
              accept={acceptableImageExtensions}
              aria-label={t('profile-widget.change-profile-photo')}
              onChange={handlePhotoUpload}
            />
            <label htmlFor={photoInputId} className={styles.uploadLabel} />
          </div>
        )}
      </Avatar>
    );
  };

  // TODO: Clarify if we need to have a resize detector here if widget is fixed width anyway.
  private readonly renderBadges = () => {
    const { host, site, computed, handlers, i18n } = this.props;
    const { navigateToViewedMember } = handlers;

    const width = host?.dimensions?.width ?? 0;
    const isIconOnly = computed.badgesSettings.layout === 'icon-only';
    const maxRows = isIconOnly || width > BREAKPOINTS.NARROW ? 1 : 3;
    const align = width > BREAKPOINTS.NARROW ? 'left' : 'center';

    return (
      <div className={styles.badgesContainer}>
        <BadgeList
          {...computed.badgesSettings}
          align={align}
          maxRows={maxRows}
          onClickMore={site.isSocial ? navigateToViewedMember : undefined}
          onBadgeClick={site.isSocial ? navigateToViewedMember : undefined}
          i18n={i18n}
        />
      </div>
    );
  };

  private readonly renderMemberDetails = () => {
    const { member, rolesMap, profilePage, site, t, computed } = this.props;
    const { badgesSettings, showTitle, showAsBlocked, showRoleLabel } =
      computed;
    const name = profilePage.editName ?? member.name;
    const hasBadges = badgesSettings.badges.length > 0;

    return (
      <div data-hook={DataHook.MemberName} className={styles.nameBox}>
        <MemberName
          name={name}
          className={styles.nameBoxText}
          tooltipClass={styles.tooltipWrapper}
        />
        {site.isSocial && showRoleLabel && (
          <RoleIcons
            containerClassName={styles.roleIconsContainer}
            iconClassName={styles.roleIcon}
            roles={member.roles}
            rolesMap={rolesMap}
            withNames={true}
            t={t}
          />
        )}
        {showTitle && this.renderTitle()}
        {site.isSocial && showAsBlocked && (
          <Blocked className={styles.blocked} t={t} />
        )}
        {hasBadges && this.renderBadges()}
      </div>
    );
  };

  private readonly renderTitle = () => {
    const { member, profilePage, computed, t } = this.props;
    const { editTitle } = profilePage;
    const { isDesignPreview } = computed;
    const title = editTitle ?? member.title;
    const shouldShowTitlePreview = isDesignPreview && !title;

    return (
      <Fragment>
        {title && (
          <div className={styles.titleWrapper}>
            <p className={classNames(styles.title)}>{title}</p>
          </div>
        )}
        {shouldShowTitlePreview && (
          <div className={styles.titleWrapper}>
            <p className={classNames(styles.title)}>
              {t('design-tab.title-preview')}
            </p>
          </div>
        )}
      </Fragment>
    );
  };

  private readonly renderFollowers = () => {
    const { member, handlers, computed } = this.props;
    const { followersInstalled, profileWidgetTexts } = computed;

    const handleFollowersBoxClick = absorbArguments(() => {
      handlers.navigateToViewedMemberFFPage(true);
    });

    const handleFollowingBoxClick = absorbArguments(() => {
      handlers.navigateToViewedMemberFFPage(false);
    });

    return (
      <div
        data-hook={DataHook.FollowersFollowing}
        className={styles.numbersBox}
      >
        <div
          data-hook={DataHook.Followers}
          className={styles.followersBoxContainer}
        >
          <Button
            className={classNames(styles.numbersBoxFollowers, {
              [styles.disabled]: !followersInstalled,
            })}
            onClick={handleFollowersBoxClick}
            isDisabled={!followersInstalled}
          >
            <span>{member.followerCount}</span>
            <div className={styles.numbersBoxText}>
              {profileWidgetTexts.followersCountersText}
            </div>
          </Button>
        </div>
        <div className={styles.divider} />
        <div data-hook={DataHook.Following}>
          <Button
            className={classNames(styles.numbersBoxFollowing, {
              [styles.disabled]: !followersInstalled,
            })}
            onClick={handleFollowingBoxClick}
            isDisabled={!followersInstalled}
          >
            <span>{member.followingCount}</span>
            <div className={styles.numbersBoxText}>
              {profileWidgetTexts.followingCountersText}
            </div>
          </Button>
        </div>
      </div>
    );
  };

  private readonly renderFollowOrChatButton = () => {
    const { member, profilePage, computed, handlers } = this.props;
    const { allowChat, showFollowButton, profileWidgetTexts, isDesignPreview } =
      computed;
    const handleFollowButtonClick = absorbArguments(handlers.followOrUnfollow);
    const handleChatButtonClick = absorbArguments(handlers.openChat);

    return (
      <FollowOrChatButton
        dataHook={DataHook.FollowCTA}
        size="medium"
        followText={profileWidgetTexts.followButtonText}
        unfollowText={profileWidgetTexts.followingButtonText}
        chatText={profileWidgetTexts.messageButtonText}
        isFollowing={member.isSubscribed}
        isDisabled={profilePage.isProfilePreview}
        shouldShowChatButton={allowChat || isDesignPreview}
        onFFClick={handleFollowButtonClick}
        onChatClick={handleChatButtonClick}
        showFollowButton={showFollowButton || isDesignPreview}
      />
    );
  };

  private readonly renderButton = ({
    dataHook,
    title,
    isSecondary,
    opaque,
    onClick,
  }: ButtonProps) => {
    const baseClassName = classNames(
      styles.mainButtonElement,
      styles.truncateText,
    );
    const buttonClassName = isSecondary
      ? classNames(
          baseClassName,
          styles.buttonSecondary,
          opaque && styles.opaque,
        )
      : classNames(baseClassName, styles.buttonPrimary);

    return (
      <Button dataHook={dataHook} className={buttonClassName} onClick={onClick}>
        {title}
      </Button>
    );
  };

  private readonly renderSocialButtons = () => {
    const { computed, handlers, t } = this.props;
    const {
      inCommunity,
      isInProfilePage,
      showEditProfileCTA,
      showViewPublicProfileCTA,
    } = computed;
    const { executeRoleAction, togglePublicProfilePreview } = handlers;

    const handleViewPublicProfileButtonClick = () => {
      togglePublicProfilePreview();
    };
    const handleCommunityButtonClick = () => {
      executeRoleAction(RoleId.JOIN_COMMUNITY);
    };

    if (!inCommunity && isInProfilePage) {
      return (
        <div className={styles.buttonsBox}>
          {this.renderButton({
            dataHook: DataHook.JoinCommunityCTA,
            title: t('MemberRoles.action_set.community'),
            isSecondary: true,
            onClick: handleCommunityButtonClick,
          })}
        </div>
      );
    }

    return showEditProfileCTA || showViewPublicProfileCTA ? (
      <div className={styles.buttonsBox}>
        <div className={styles.mainButton}>
          {showViewPublicProfileCTA && (
            <Fragment>
              {this.renderButton({
                dataHook: DataHook.ViewPubLicProfileCTA,
                title: t('profile-widget.view-public-profile'),
                isSecondary: true,
                onClick: handleViewPublicProfileButtonClick,
              })}
            </Fragment>
          )}
        </div>
      </div>
    ) : null;
  };

  private readonly renderButtons = () => {
    const { profilePage, site, computed } = this.props;
    const { isSocial } = site;
    const { isProfilePreview } = profilePage;
    const { canEdit, isDesignPreview } = computed;

    const isDesignPreviewAndSocial = isDesignPreview && isSocial;

    if (canEdit && isSocial && !isProfilePreview && !isDesignPreview) {
      return this.renderSocialButtons();
    }

    return !canEdit || isProfilePreview || isDesignPreviewAndSocial ? (
      <div className={styles.buttonsBox}>
        <div className={styles.mainButton}>
          {this.renderFollowOrChatButton()}
        </div>
      </div>
    ) : null;
  };

  private readonly renderActions = () => {
    const { member, isMobile, isRTL, computed, handlers } = this.props;
    const { isProfilePreview } = this.props.profilePage;
    const { additionalActions, rolesActions, isResponsiveEditor } = computed;
    const { uid } = member;

    if (rolesActions.length === 0 && additionalActions.length === 0) {
      return;
    }

    const handleRoleAction = (roleId: RoleId) => {
      if (roleId === RoleId.CONTACT_PAGE) {
        const { metaSiteId } = this.props;
        return maybeOpenContactsWindow(metaSiteId, uid, isResponsiveEditor);
      }

      handlers.executeRoleAction(roleId);
    };

    return (
      <MemberMoreButton
        containerClass={styles.moreActions}
        additionalActions={additionalActions}
        rolesActions={rolesActions}
        alignment={Position.Right}
        isRtlLocale={isRTL}
        isMobile={isMobile}
        isDisabled={isProfilePreview}
        onAdditionalAction={handlers.executeAdditionalAction}
        onRoleAction={handleRoleAction}
      />
    );
  };
}

export default DesktopNonResponsiveWidget;
